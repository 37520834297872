.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;
    min-height: 200px;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.p-autocomplete-multiple-container {
    max-height: 44px;
    overflow-y: auto!important;
}

.p-autocomplete-multiple-container:focus-within {
    max-height: 100%;
}

/*
* override grid class from primeflex to add negative margin-bottom
* and fix nested grids
*/
.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    margin-top: -1rem;
    margin-bottom: -1rem;
}

/*
* add more flex-order classes since we have layouts with more than 6 divs to order
*/
@for $i from 7 through 10 {
    .flex-order-#{$i} {
        order: #{$i};
    }
}

.expired {
    color: var(--red-200);
    font-style: normal;
    font-size: 11px;
    line-height: 17px;
}
