@import './_variables';
@import "./_mixins";
@import "./_preloading";
@import "./_main";
@import "./_topbar";
@import "./_menu";
@import "./_config";
@import "./_content";
@import "./_footer";
@import "./_responsive";
@import "./_utils";
@import "./_typography";

.overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 100000; /* Specify a stack order in case you're using a different order for other elements */
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border: 0px none #ffffff;
    border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
}
::-webkit-scrollbar-thumb:active {
    background: #2563eb;
}
::-webkit-scrollbar-track {
    background: #eff3f8;
    border: 0px none #ffffff;
    border-radius: 8px;
}
::-webkit-scrollbar-track:hover {
    background: #f5f9ff;
}
::-webkit-scrollbar-track:active {
    background: #f5f9ff;
}
::-webkit-scrollbar-corner {
    background: transparent;
}

.p-scrollpanel {
    p {
        padding: .5rem;
        line-height: 1.5;
        margin: 0;
    }

    &.primary-scroll-bar {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--layer-1);
        }

        .p-scrollpanel-bar {
            background-color: var(--primary-color);
            opacity: 1;
            transition: background-color .2s;

            &:hover {
                background-color: #007ad9;
            }
        }
    }
}