@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/compass/styles/flags/flags.css";
@import "assets/compass/styles/badges.scss";
@import "assets/compass/styles/code.scss";
